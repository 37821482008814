<template>
     <hooper :centerMode="true"  class="h-100" :settings='hooperSettings' >
            <slide  v-for="(item, n) in cards" :key="n">
              <advantages-card :description="item.text" :title="item.title" :imageUrl="require(`@/assets/images/page-img/${item.icon}`)"/>
            </slide>
          <hooper-pagination slot="hooper-addons"></hooper-pagination>
        </hooper>
</template>

<script>
import AdvantagesCard from '@/components/socialvue/cards/AdvantagesCard.vue';
import { Hooper, Slide,
  Pagination as HooperPagination } from 'hooper';
import 'hooper/dist/hooper.css';

export default {
    name: 'AdvantagesCarrousel',
    props: ['cards'],
    components: {
        AdvantagesCard,
        Hooper,
        HooperPagination,
        Slide
    },
    data () {
        return {
            hooperSettings: {
                    infiniteScroll: true,
                    centerMode: true,
                    autoPlay: true,
                    playSpeed: 3500,
                    breakpoints: {
                        2400: {
                            itemsToShow: 4
                        },
                        1800: {
                            itemsToShow: 4
                        },
                        1500: {
                            itemsToShow: 3
                        },
                        900: {
                            itemsToShow: 3
                        },
                       500: {
                            itemsToShow: 2
                        },
                        0: {
                            itemsToShow: 2
                        }
                    }
                }
        };
    }
};
</script>
