<template>
  <v-container class="container-prosker">
    <div>
      <v-card
        :img="require('../../assets/images/page-img/Slide 1 1.png')"
        flat
        :height="!$vuetify.breakpoint.mobile ? 500 : 160"
        class="mb-10 relative"
      />
    </div>
    <!-- <div class="d-flex justify-content-center mb-4">
      <h1 v-if="!$vuetify.breakpoint.mobile" class="secondary-prosk font-weight-bold">{{ $t("buyEasily") }}</h1>
      <h3 v-else class="secondary-prosk font-weight-bold text-center">{{ $t("buyEasily") }}</h3>
    </div> -->
    <v-row   :style="{
    backgroundImage: `url(${require('@/assets/images/page-img/Vector_13.png')})`,
    backgroundPosition: 'center bottom'
  }" >
     <v-col col="12" md="6">
        <h1 class="text-h4 secondary--text font-weight-bold text-center text-md-left">{{ $t("buyEasily") }}</h1>
      </v-col>
      <v-col col="12" md="6" class="mb-5">
        <p class="text-center text-md-left font-size-16 mt-4 w-50"><span class="main_text_color--text" v-html="$t('dedicate_love')"/>
        <div class="d-flex justify-content-center justify-content-md-start">
        <general-button  :loading="false" :message="$t('wantToSignUp')" size="medium" cssClasses="mt-4" primary/>
        </div>
      </v-col>
      <v-col v-show="$vuetify.breakpoint.mobile" cols="12" class="text-center">
        <advantages-carrousel :cards="cardsAdvantages" />
      </v-col>
      <v-col col="12" md="4"  v-for="(item, n) in cardsAdvantages" :key="n" v-show="!$vuetify.breakpoint.mobile" >
          <advantages-card :description="item.text" :title="item.title"  :imageUrl="require(`@/assets/images/page-img/${item.icon}`)" maxWith="100%"/>
      </v-col>
      <v-col class="d-flex justify-content-center">
        <general-button  :loading="false" message="Quiero Registrarme" size="medium" cssClasses="mt-4" secondary/>
      </v-col>
      <!-- <v-card
        :height="$vuetify.breakpoint.mobile ? 1100 : 480"
        class="info-card width-100"
      >
        <div class="line-separator secondary-prosk" :class="$vuetify.breakpoint.mobile ? 'mobile' : ''">
          <div class="sized-div">
            <div class="bg-white custom-radio pt-10 pl-10">
              <img
                :src="require('../../assets/images/page-img/potence1.svg')"
                alt="subscription"
              />
            </div>
            <h4 class="font-weight-bold secondary-prosk mb-6">{{ $t("findJobs") }}</h4>
            <p class="paragraph">{{ $t("findJobsDescription") }}</p>
          </div>
          <div class="sized-div">
            <div class="bg-white custom-radio pt-10">
              <img
                :src="require('../../assets/images/page-img/tarifa.svg')"
                alt="subscription"
              />
            </div>
            <h4 class="font-weight-bold secondary-prosk mb-6">{{ $t("buySafe") }}</h4>
            <p class="paragraph">{{ $t("buySafeDescription") }}</p>
          </div>
          <div class="sized-div">
            <div class="bg-white custom-radio">
              <img
                :src="require('../../assets/images/page-img/formation.svg')"
                alt="subscription"
              />
            </div>
            <h4 class="font-weight-bold secondary-prosk mb-6">{{ $t("chatByYourself") }}</h4>
            <p class="paragraph">{{ $t("chatByYourselfDescription") }}</p>
          </div>
        </div>
        <div class="d-flex justify-content-center">
          <v-btn :to="{ name: 'auth1.sign-up1' }" color="#59D79D" rounded>
            <span class="secondary-prosk font-weight-bold">{{ $t('createAccount') }}</span>
          </v-btn>
        </div>
      </v-card>
      <v-card class="info-card secondary-prosk">
        <v-col cols="12" class="text-center mb-4">
          <h2 class="secondary-prosk font-weight-bold">{{ $t("faqs") }}</h2>
        </v-col>
        <v-expansion-panels accordion flat class="mb-6" focusable v-model="activeTab">
          <v-expansion-panel v-for="(item, i) in faqs" :key="i" class="bg-grey">
            <v-expansion-panel-header
                class="font-weight-bold secondary-prosk"
                :class="{'bg-green-prosk': i === activeTab}"
            >
              <template v-slot:actions>
                <v-icon :class="i === activeTab ? 'text-white' : 'secondary-prosk'">
                  {{  i === activeTab ? 'mdi-minus' : 'mdi-plus' }}
                </v-icon>
              </template>
              {{ $t(item.question) }}
            </v-expansion-panel-header>
            <v-expansion-panel-content class="mt-5 secondary-prosk">
              {{ $t(item.answer) }}
              <router-link v-show="item.link" :to="{ name: item.link }"> {{ $t('signUp') }}</router-link>
              <span class="secondary-prosk" v-show="item.answer2">{{$t(item.answer2)}}</span>
              <span class="secondary-prosk" v-show="item.answer3">{{$t(item.answer3)}}</span>
              <span class="secondary-prosk" v-show="item.answer4">{{$t(item.answer4)}}</span>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card> -->
    </v-row>
    <faq-section />
  </v-container>
</template>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import GeneralButton from '@/components/socialvue/buttons/GeneralButton.vue';
import AdvantagesCarrousel from '@/components/socialvue/advantagesCarrousel/AdvantagesCarrousel.vue';
import AdvantagesCard from '@/components/socialvue/cards/AdvantagesCard.vue';
import FaqSection from '@/components/socialvue/faqSection/FaqSection.vue';
export default {
  name: 'HowWorksClients',
  components: {
    GeneralButton,
    AdvantagesCarrousel,
    AdvantagesCard,
    FaqSection
  },
  data () {
    return {
      items: [
        {
          step: 1,
          text: 'createYourProskerProfile'
        },
        {
          step: 2,
          text: 'findNeed'
        },
        {
          step: 3,
          text: 'solveProblems'
        }
      ],
      activeTab: null,
      subscriptionInformation: {},
      faqs: [
        {
          question: 'whatIsPROSK',
          answer: 'isAPlatformWeSeekContributeToDigitalTransformationOfMYPIMES',
          answer2: 'isAPlatformWeSeekContributeToDigitalTransformationOfMYPIMES2',
          answer3: 'isAPlatformWeSeekContributeToDigitalTransformationOfMYPIMES3',
          answer4: 'isAPlatformWeSeekContributeToDigitalTransformationOfMYPIMES4'
        },
        {
          question: 'howDoIRegisterInPROSKClient',
          answer: 'createYourUsernamePasswordCompletingFormClient',
          link: 'auth1.sign-up1',
          answer2: 'thenCompleteYouProfileClient'
        },
        {
          question: 'canConnectToAProsker',
          answer: 'connectProsker',
          answer2: 'connectProsker2',
          answer3: 'connectProsker3'
        },
        {
          question: 'howDoUsersContactMeClient',
          answer: 'usersRegisteredInPROSKWillSendYouMessageClient'
        },
        {
          question: 'howDoesPROSKNotifyMeClient',
          answer: 'currentlyFromProfileWillHaveNotificationsClient'
        },
        {
          question: 'canIChargeServicesClient',
          answer: 'soonPROSKWillHaveServiceAcceptPaymentsClient'
        },
        {
          question: 'paymentIsSafeClient',
          answer: 'paymentAnswerClient'
        },
        { question: 'canIScheduleClient', answer: 'soonPROSKWillHaveDigitalAgendaClient' },
        {
          question: 'doesPROSKChargeCommissionClient',
          answer: 'noThatDependsHowYouUsePlatformClient'
        },
        {
          question: 'ifIGetJobThroughAmICoveredClient',
          answer: 'youAreResponsibleComplyingObligationsClient'
        },
        {
          question: 'dataIsSafe',
          answer: 'dataIsSafeAnswer',
          answer2: 'dataIsSafeAnswer2'
        }
      ],
      subscriptionType: {},
      subscriptionPlans: [],
      cardsAdvantages: [
        {
          icon: 'calendar-add--add-calendar-date-day-month.svg',
          title: this.$t('bringWithYou'),
          text: this.$t('manageFromWherever')
        },
        {
          icon: 'dollar-coin-1--accounting-billing-payment-cash-coin-currency-money-finance.svg',
          title: this.$t('acceptPayment'),
          text: this.$t('acceptPaymentAnswer')
        },
        {
          icon: 'chat-bubble-oval-notification--messages-message-bubble-chat-oval-notify-ping.svg',
          title: this.$t('comunication'),
          text: this.$t('comunicationSubtext')
        }
      ]
    };
  },
  created () {
    window.scroll({
      top: 0,
      behavior: 'smooth'
    });
    this.getSubscriptionPlans();
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser'
    })
  },
  methods: {
    sendEmailOrLogin () {
      if (!this.currentUser) {
        this.$router.push({ name: 'auth1.sign-in1' });
      } else {
        const url = `${process.env.VUE_APP_BASE_URL}/api/subscriptions/contact`;
        const data = {
          user: this.currentUser
        };
        axios.post(url, data).then(() => {
          this.$swal.fire({
            title: this.$t('awesome'),
            text: this.$t('queryReceived'),
            confirmButtonColor: '#19AD93'
          });
        });
      }
    },
    sendToRegisterFreePlan () {
      this.subscriptionType.free = true;
      this.$router.push({
        name: 'auth1.sign-up1',
        params: { query: JSON.stringify(this.subscriptionType) }
      });
    },
    sendToCheckoutPremiumPlan (subscription) {
      this.subscriptionInformation = subscription;
      this.subscriptionInformation.isSubscriptionType = true;
      this.subscriptionInformation.isUserLogged = true;
      this.$router.push({
        name: 'checkout',
        params: { query: JSON.stringify(this.subscriptionInformation) }
      });
    },
    sendToCheckoutNotLoggedPremiumPlan (subscription) {
      this.subscriptionInformation = subscription;
      this.subscriptionInformation.isSubscriptionType = true;
      this.subscriptionInformation.isUserLogged = false;
      this.$router.push({
        name: 'checkout',
        params: { query: JSON.stringify(this.subscriptionInformation) }
      });
    },
    beginOnProsk (subscription) {
      if (!this.currentUser && subscription.id === 1) {
        this.sendToRegisterFreePlan();
      } else if (this.currentUser && subscription.id === 2) {
        this.sendToCheckoutPremiumPlan(subscription);
      } else if (!this.currentUser && subscription.id === 2) {
        this.sendToCheckoutNotLoggedPremiumPlan(subscription);
      }
    },
    getSubscriptionPlans () {
      const url = `${process.env.VUE_APP_BASE_URL}/api/subscriptions`;
      this.subscriptionPlans.splice(0, this.subscriptionPlans.length);
      axios.get(url).then((response) => {
        response.data.data.forEach((category) => {
          this.subscriptionPlans.push(category);
        });
      });
    }
  },
  mounted () {
    this.trackUserData('load_content', {
      pageType: 'how_to_work'
    });
  }
};
</script>
<style scoped>
  .container {
    width: 100%;
    height: 100%;
  }

  .banner {
    max-height: 100%;
    width: 800px;
  }

  .title {
    font-size: 2rem;
    font-weight: bold;
  }

  .info-card {
    border-radius: 12px;
    margin: 24px 36px;
    width: calc(100%-72px);
    background-color: #f1f1f1;
    color: #3F44A6;
    box-shadow: 12px 10px 5px rgba(0, 0, 0, .1);
  }

  .width-50 {
    width: 50%;
    margin: 12px 20px;
  }

  .width-90 {
    width: 90%;
    padding-left: 10%;
  }

  .width-100 {
    width: 100%;
  }

  .sized-div {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .paragraph {
    width: 300px;
  }

  .custom-radio {
    border-radius: 100%;
    height: 180px;
    width: 160px;
    padding: 24px;
    box-shadow: 4px 2px 2px rgba(0, 0, 0, .1);
    margin-bottom: 12px;
  }

  .line-separator {
    padding: 40px 0;
    width: 100%;
    display: flex;
    justify-content: space-around;
  }

  .mobile {
    flex-direction: column;
  }

</style>
